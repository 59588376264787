import React, {useState,useEffect} from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import { useMyRegistration } from "../../contexts/myregistration.js";
// import styled from 'styled-components';

import {
  useRouteMatch,
	useHistory
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'; 

import CreditCardForm from '../Payments/CreditCardForm.js';
import PuppyPile from './PuppyPile.js';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import { LoadingSpinner } from "../../styled-components/AuthForms.js";
import { FormCard, FormLabel, RegularButton } from "../../styled-components/Forms.js";
import { BreadcrumbLink, ButtonLink } from "../../styled-components/SmallElements.js";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
// import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Carousel from 'react-bootstrap/Carousel'
// import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'


import Cabin1 from "../../assets/Cabin1.jpg"; 
import Cabin2 from "../../assets/Cabin2.jpg"; 
import Cabin3 from "../../assets/Cabin3.jpg"; 
import Cabin4 from "../../assets/Cabin4.jpg"; 
import Yurt1 from "../../assets/Yurt1.jpg"; 
import Yurt2 from "../../assets/Yurt2.jpg"; 
import Yurt3 from "../../assets/Yurt3.jpg"; 
import Yurt4 from "../../assets/Yurt4.jpg"; 
import Yurt5 from "../../assets/Yurt5.jpg"; 

import './PuppyPile.css'; 

library.add(
	faArrowLeft,
	faInfoCircle
);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);




function Housing(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
  let history = useHistory();
  let match = useRouteMatch();
	
	const [showHousingTypes, setShowHousingTypes] = useState((match.path==='/puppypile/housingtypes')?true:false);
	const [showLodgeInfo, setShowLodgeInfo] = useState((match.path==='/puppypile/lodgeinfo')?true:false);
	const [loadingLinenStatus, setLoadingLinenStatus] = useState(true);
	
	const [housingPreference,setHousingPreference] = useState(''); 
	const [rentingLinens,setRentingLinens] = useState(false); 
	const [showPaymentPanel,setShowPaymentPanel] = useState(false); 
	const [showCancelLinensSpinner,setShowCancelLinensSpinner] = useState(false); 
	const [showCancelLinensError,setShowCancelLinensError] = useState(false); 
	const [cancelLinensErrorMsg,setCancelLinensErrorMsg] = useState(''); 
	const [linenSuccessMessage,setLinenSuccessMessage] = useState(null); 
	
	useEffect(()=>{ 
		if(myRegistration.housing) setHousingPreference(myRegistration.housing.preference); 
		if(myRegistration.housing && myRegistration.housing.linenRental){ 
			setRentingLinens(myRegistration.housing.linenRental.renting); 
			setLoadingLinenStatus(false); 
		}
	},[myRegistration]); 
	
	
	
	function goLinkHousingTypes(){ 
		history.push('/puppypile/housingtypes'); 
		setShowHousingTypes(true); 
	} 
	function goLinkLodge(){ 
		history.push('/puppypile/lodgeinfo'); 
		setShowLodgeInfo(true); 
	} 
	function closeLinkHousingTypes(){ 
		history.push('/puppypile'); 
		setShowHousingTypes(false); 
	} 
	function closeLinkLodgeInfo(){ 
		history.push('/puppypile'); 
		setShowLodgeInfo(false); 
	}
	function handleHousingPreference(val){ 
		setHousingPreference(val); 
		if(showHousingTypes){ 		
			history.push('/puppypile'); 
			setShowHousingTypes(false); 
		}
		myRegistration.housing.preference = val; 
		setMyRegistration(myRegistration); 
	} 
	function startLinenRental(){ 
		setShowPaymentPanel(true); 
	} 
	function finishLinenPayment(paymentId){ 
		setShowPaymentPanel(false); 
		myRegistration.housing.linenRental.renting = true; 
		myRegistration.housing.linenRental.payment = paymentId; 
		setMyRegistration(myRegistration); 
		setLinenSuccessMessage("You have reserved a set of linens!"); 
		setTimeout(function(){ setLinenSuccessMessage(''); },10000); 
	} 
	function cancelLinenRental(){ 
		setShowCancelLinensSpinner(true); 

		axios.delete(process.env.REACT_APP_API_HOST+"/payments/"+myRegistration.housing.linenRental.payment,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setShowCancelLinensSpinner(false); 
										myRegistration.housing.linenRental.renting = false; 
										myRegistration.housing.linenRental.payment = ''; 
										setMyRegistration(myRegistration);  
										setLinenSuccessMessage('Your reservation has been cancelled. You can always re-reserve later.'); 
										setTimeout(function(){ setLinenSuccessMessage(''); },10000); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
										setShowCancelLinensError(true); 
										setShowCancelLinensSpinner(false); 
										setCancelLinensErrorMsg(result.data.errormsg+" Please send a message to the PITM Directors to cancel your linen reservation."); 
									}
								}).catch(function (error) {
									if (error.response) {
										setShowCancelLinensError(true); 
										setShowCancelLinensSpinner(false); 
										setCancelLinensErrorMsg(error.response.data.errormsg+" Please send a message to the PITM Directors to cancel your linen reservation."); 
									}});
	}
	
	
	const mainInterface = <>
						<Row>
							<Col>
									<FormCard id="" className="blue no-bottom-margin">
										<Card.Header as="h5">Housing Preference</Card.Header>
										<Card.Body>
											<p>Let us know if you prefer to stay in a cabin or a yurt, and we'll do our best to accommodate your preference. </p>
											<Form.Row>
												<Form.Group as={Col}>
													<FormLabel.Primary>My Preference</FormLabel.Primary>
													<Form.Control id="profile_role" as="select" value={housingPreference} onChange={e => { handleHousingPreference(e.target.value); }} >
															<option></option>
															<option value="cabin">Cabin</option>
															<option value="yurt">Yurt</option>
															<option value="either">Either</option>
													</Form.Control>
												</Form.Group>
											</Form.Row>
											<ButtonLink onClick={goLinkHousingTypes} className="single-line"><FontAwesomeIcon icon={['far', 'info-circle']} />Learn More about Yurts &amp; Cabins</ButtonLink>
											<ButtonLink onClick={goLinkLodge} className="single-line"><FontAwesomeIcon icon={['far', 'info-circle']} />Info On the New Lodge!</ButtonLink>
										</Card.Body> 
									</FormCard>
								</Col>
							<Col>
									<FormCard id="" className="gold full-height">
										<Card.Header as="h5">Linen Rental</Card.Header>
										<Card.Body>
											{ loadingLinenStatus && <LoadingSpinner><Spinner animation="grow" variant="warning" /></LoadingSpinner> }
											{ linenSuccessMessage && <Alert variant="success">{linenSuccessMessage}</Alert> }
											{ !loadingLinenStatus && rentingLinens && <>
												<b>You have reserved linens from PITM.</b><br /> This includes sheets, a blanket & pillow, and a bath towel. If you change your mind, click the link below to cancel your reservation and get a refund.
												{ showCancelLinensError && <Alert variant="danger">{cancelLinensErrorMsg}</Alert> }
												{ (showCancelLinensSpinner) ? <LoadingSpinner><Spinner animation="grow" variant="success" /></LoadingSpinner>
													: <><RegularButton className="full-width low-key cancel" onClick={()=>{ cancelLinenRental(); }}>Cancel my Linen Reservation</RegularButton></>}
											</>
											}
											{ !loadingLinenStatus && !showPaymentPanel && !rentingLinens && <>
												<p>You will need to bring your own sheets, blanket, pillow, and bath towels. Or you can rent these from PITM for $25 to save space in your suitcase. Supplies are limited so be sure to reserve yours early!</p>
												<RegularButton className="full-width" onClick={()=>{ startLinenRental(); }}>I Want to Rent Linens</RegularButton>
											</>}
											{ showPaymentPanel && 
													<Elements stripe={stripePromise}>
														<CreditCardForm amount={25} onSuccess={finishLinenPayment} paymentType="linens" />
													</Elements>
											} 
										</Card.Body> 
									</FormCard>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormCard id="" className="red force-top-margin">
										<Card.Header as="h5">Puppy Pile</Card.Header>
										<Card.Body>
											<PuppyPile />
										</Card.Body>
									</FormCard>
								</Col>
							</Row>
							</>;

	const housingTypes = <Row>
							<Col>
								<BreadcrumbLink onClick={closeLinkHousingTypes}><FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" /> Back to My Housing & Puppy Pile</BreadcrumbLink>
									<FormCard id="" className="blue">
										<Card.Header as="h5">Housing Options</Card.Header>
										<Card.Body>
											<p>Our venue has two types of housing to choose from: cabins &amp; yurts. 
												We will assign housing based on the number of registered guests, puppy pile sizes, and mobility requirements. 
												We will try (but cannot guarantee) to match your housing with your preferences and requests.</p>
											<Row>
												<Col sm={12} md={6}>
													<h5>Cabins</h5>
													<Carousel>
														<Carousel.Item><img className="d-block w-100" src={Cabin1} alt="Cabin" /> </Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Cabin2} alt="Cabin" /> </Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Cabin3} alt="Cabin" /></Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Cabin4} alt="Cabin" /></Carousel.Item>
													</Carousel>
													<ul className="featuresList">
														<li>Most rooms holds 3-4 people, and each cabin houses between 10 and 20 people</li>
														<li>Beds are twin-sized and are a mix of bunk beds and singles. You can push the beds together for more cuddle space.</li>
														<li>Every cabin is heated and has a cozy common room with a fireplace</li>
														<li>Two bathrooms in each cabin.</li>
														<li>Cabins are on the main loop road, with parking spaces.</li>
														<li>Farthest walking distance to Mosh Hall: 1,000 feet across the field or 1/3 mile via the main loop road.</li>
														<li>Some cabins are ADA accessible.</li>
													</ul>
												</Col>
												<Col sm={12} md={6}>
													<h5>Yurts</h5>
													<Carousel>
														<Carousel.Item><img className="d-block w-100" src={Yurt1} alt="Yurt" /></Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Yurt2} alt="Yurt" /></Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Yurt3} alt="Yurt" /></Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Yurt4} alt="Yurt" /></Carousel.Item>
														<Carousel.Item><img className="d-block w-100" src={Yurt5} alt="Cabin" /></Carousel.Item>
													</Carousel>
													<ul className="featuresList">
														<li>A more rustic and private experience - the Yurt Village is tucked away in the trees (with an access road and parking). Daytime dress code still applies.</li>
														<li>Each yurt holds up to 12 people with bunk beds. Puppy Piles of 9+ people will have their own yurt.</li>
														<li>Central heated bathhouse with multiple toilet, shower, and sink facilities. (No facilities in the yurts.)</li>
														<li>Yurts have electricity, but are not heated and technically fire code prevents space heaters.</li>
														<li>Farthest walking distance to Mosh Hall: 1/3 mile across the field or 1/2 mile via the main loop road.</li>
														<li>One yurt and a bathroom are ADA accessible.</li>
													</ul>
												</Col>
											</Row>
										</Card.Body> 
									</FormCard>
									<FormCard id="" className="gold">
										<Card.Header as="h5">What's Your Preference?</Card.Header>
										<Card.Body>
											<Row>
												<Col sm={12} md={4}>
													<RegularButton className="full-width" onClick={()=>{ handleHousingPreference('cabin'); }}>I Prefer a Cabin</RegularButton>
													{housingPreference==='cabin' && <Form.Text muted className="current-pref-indicator">This is your current preference.</Form.Text>}
												</Col>
												<Col sm={12} md={4}>
													<RegularButton className="full-width" onClick={()=>{ handleHousingPreference('yurt'); }}>I'd Like to Stay in a Yurt</RegularButton>
													{housingPreference==='yurt' && <Form.Text muted className="current-pref-indicator">This is your current preference.</Form.Text>}
												</Col>
												<Col sm={12} md={4}>
													<RegularButton className="full-width" onClick={()=>{ handleHousingPreference('either'); }}>Either is Fine with Me!</RegularButton>
													{housingPreference==='either' && <Form.Text muted className="current-pref-indicator">This is your current preference.</Form.Text>}
												</Col>
											</Row>
										</Card.Body> 
									</FormCard>
								</Col>
							</Row>;
	
	const lodgeInfo = <Row>
							<Col>
								<BreadcrumbLink onClick={closeLinkLodgeInfo}><FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" /> Back to My Housing & Puppy Pile</BreadcrumbLink>
									<FormCard id="" className="blue">
										<Card.Header as="h5">Lodge Info</Card.Header>
										<Card.Body>
											<p>The new facility is open! With 15 private rooms, each with two beds and a private bathroom, the lodge is spacious and comfortable, 
												and perfect for those pups & handlers that need a little more personal space. The additional cost for these rooms is $225 per person. </p>
											<p><b>Upgrade</b></p>
											<p>If you didn't choose to upgrade during registration, you can still do so as long as we have space. Email <a href="mailto:directors@puppiesinthemountains.com">directors@puppiesinthemountains.com</a> to request an upgrade.</p>
											<p><b>Puppy Piles</b></p>
											<p>If you're staying in the lodge, you can only create a Puppy Pile with up to two people, and both of you will need to have chosen the Lodge during registration or upgrade. If you do not have a Puppy Pile, you will have a roommate.</p>
										</Card.Body> 
									</FormCard>
								</Col>
							</Row>;
  return (<Container>
						<h1>My Housing &amp; Puppy Pile</h1>
						{/* <p><b>Important: To have enough time to prepare for the event, we can no longer accept changes to Puppy Piles or Housing Preferences. Any changes you make will not be seen by our event staff. If you have an urgent concern, please contact Rook on Telegram.</b></p> */}
						{ (showHousingTypes || showLodgeInfo ) ? ((showHousingTypes)?housingTypes:lodgeInfo) : mainInterface }
						</Container>
					);
}
 
export default Housing;